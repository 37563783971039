import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Latestpost from "../components/latestPost"

const IndexPage = ({ data }) => {
  const {description, siteUrl, twitter} = data.site.siteMetadata
  const post = data.allMarkdownRemark.nodes[0]
  const { frontmatter } = post

  return (
    <Layout>
      <Seo 
            title={'Latest Post: ' + frontmatter.title}
            description={frontmatter.description.replace(/(<([^>]+)>)/gi, "")} 
            image = {frontmatter.hero_image }
            keywords = {frontmatter.keywords}
      />
      <div className="italic text-center mx-auto max-w-full md:max-w-2xl py-8">
        {`${description} Read the latest of ${data.allMarkdownRemark.totalCount.toLocaleString()} posts:`}
      </div>
      <Latestpost post={post} siteUrl={siteUrl} twitter={twitter}></Latestpost>
    </Layout>
  )  
}


export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
        twitter
        siteUrl
      }
    }
    allMarkdownRemark(limit: 1, sort: {order: DESC, fields: frontmatter___date}) {
      totalCount
      nodes {
        html
        timeToRead
        id
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          description
          hero_image_alt
          hero_image
          hero_image_credit
          keywords
          publication
          author
          authorbio
          webUrl
          starRating
        }
      }
    }
  }
`

export default IndexPage
