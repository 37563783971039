import * as React from 'react'

import ArticleTemplate from "./articleTemplate"

const Latestpost = ({ post, siteUrl, twitter }) => {
    return (
        <ArticleTemplate post={post} siteUrl={siteUrl} twitter={twitter}></ArticleTemplate>
    )
}

export default Latestpost